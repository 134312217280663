import { Routes } from "./Routes"


import { createStore } from "redux"
import {
  basicReducer,
  initialState,
  logOutAction,
} from "ftl-core"
import { UIMain } from "ftl-dashboards-templates"
import { AuthAPI } from "./repository/common"
import {
  FoodTechLabLogo,
  OrderIcon,
} from "./pages/commons/icons/icons"
import { Email, MoreHoriz } from "@material-ui/icons"
import React from "react"
import { FoodTechLabCallCenterTheme } from "./Theme"


const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export const IS_MOBILE_SCREEN_WIDTH = window.innerWidth < 960
const sideMenuItems = [
  {
    icon: <OrderIcon />,
    label: "Заказы",
    to: "/orders",
  },
  {
    icon: <OrderIcon />,
    label: "Необработанные заказы",
    to: "/with-external-problem",
  },
  {
    icon: <Email />,
    label: "SMS",
    to: "/sms",
  },
]

export const App = () => {
  return (
    <>
      <UIMain
        redirectOnAuthUrl="/with-external-problem"
        project={{
          title: "FEat Call Center",
          logo: {
            authPage: <div style={{ width: 40, height: 40, margin: "0 auto" }}><FoodTechLabLogo /></div>,
            header: <div style={{ width: 24, height: 24 }}><FoodTechLabLogo /></div>,
          },
        }}
        theme={FoodTechLabCallCenterTheme}
        AuthClient={AuthAPI}
        StoreProviderProps={{
          store,
          getProfile: () => {
            //заглушка: единственный способ избежать вытягивание профиля при загрузке приложения
            return Promise.resolve({
              data: {
                result: {
                  id: "",
                  createdAt: "",
                  email: "",
                  firstName: "",
                  lastName: "",
                  accountStatus: "",
                },
              },
              status: 200,
              statusText: "OK",
              headers: {},
              config: {},
            })
          },
        }}
        BaseLayoutProps={{
          onSignOut: () => store.dispatch(logOutAction),
          profileMenuItems: sideMenuItems,
          sideMenuItems: sideMenuItems,
        }}
      >
        <Routes />
      </UIMain>

    </>
  )
}

export default App
